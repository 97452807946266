.div-img-gal img
{
padding: 2px;
border-radius: 5%;

  
}

.div-img-gal
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px; /* Adds 20px gap between divs */
    margin: auto;
   
 }

 @media only screen and (max-width: 870px) {
    
    .div-img-gal img
    {
     width: 33%;
     height: auto;
    }
    
}