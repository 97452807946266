#table-container {
    width: 100%;
    overflow-x: auto; /* Allows horizontal scrolling if the table is too wide */

  }
  
  table {
    width: 100%; /* Make the table take the full width of its container */
    border-collapse: collapse;
    table-layout: auto; /* Adjusts column width based on content */
  }

  @media screen and (max-width: 768px) {
    th, td {
      font-size: 14px; /* Reduce font size for small screens */
    }
    
    table {
      font-size: 12px; /* Further reduce table font size on small devices */
    }
  }