.main-body
{
  width: 100%;
  height: 25%;
  align-items: center;
  background-color: rgb(243, 252, 255);
 
}


.form-container-2 {
    width: 50%;
    height: 100%;
    margin: 0 auto;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #ecdbdb;
  }
  
  h3 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
   
      padding: 8px;
      border-radius: 10%;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-family: "NotoSerifMalayalam";
        font-weight: 200;
        font-style:normal;
        font-size: 18px;
  }
  
  input{
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  
  @media only screen and (max-width: 870px) {
    .form-container-2 {
      width: 96%;
       
    }
  }

  @font-face {
    font-family: NotoSansMalayalam;
    src: url("./MyFonts/NotoSansMalayalam.ttf");
  }

  #reg-button
  {
        height: 45px;

    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
  }

  select{
        margin-left: 10px;
    width: 96%; /* Make all form inputs (select and text) the same width */
    box-sizing: border-box; /* Ensure padding is included in width */
  }

  #label-bg
  {
    padding-left: 8px;
  }

  #bloodgroup
  {
    height: 45px;
  }