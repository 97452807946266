.menu-div img
{
border-radius: 50%;
border: 4px solid gray;
box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); /* Adds shadow */
}

.menu-div
{
    display: flex;
justify-content: center;
align-items: center;
overflow-y: auto;
background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 100%);
gap: 10px; /* Adds 20px gap between divs */
border: 4px solid gray;
box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.9); /* Adds shadow */
}

.menu-div img:hover
{
    border-color: crimson;
    border-width: 5px;
    box-shadow: 5px 5px 10px yellow; /* Adds shadow */
    width: 105px;
    height: 105px;
}

