.page-container {
    display: flex;
    flex-wrap: wrap;  /* Allows columns to wrap when the screen is too narrow */
    gap:10px;        /* Adds space between the columns */
    padding: 20px;    /* Adds padding inside the container */

  }
  
  .column {
    flex: 1;           /* Each column will take up equal space */
    height:75vh;
    min-width: 300px;   /* Ensures a minimum width before wrapping */
    background-color: #f4f4f4; /* Example background color for visibility */
    padding: 10px;     /* Padding inside each column */
    border-radius: 8px; /* Rounded corners for columns */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
   
  }
  
  .left-column {
    background-color: #d1e7dd; /* Example color for the left column */
  }
  
  .right-column {
    background-color: #f8d7da; /* Example color for the right column */
  }
  
  /* Optional: Media query for smaller screens */
  @media (max-width: 600px) {
    .column {
      flex-basis: 100%; /* Forces columns to stack on small screens */
    }
  }
  