   .menu-text
   {
    font-family: "CuteFont-Regular";
    font-weight: 200;
    font-style:normal;
    font-size: 22px;
   }


  li {
    float: left;
      }
  
  li a {
    display: block;
    color: white;
    text-align: center;
    padding: 10px 10px;
    text-decoration:wavy;
  }
  
  /* Change the link color to #111 (black) on hover */
  li a:hover {
    background-color: #002699;
  }

  
  

.logo-img
{
border-radius: 50px;
height: 100px;
width: 100px;
}

.clscarousel
{
    border-radius: 5px;    
}

.mwa-logo
{

  border-radius: 50%;
  width: 100px;
  position:absolute;
    }

    .nav-main
{
  position:fixed;
  z-index: 1000; /* Ensures the navbar stays on top of other elements */
  align-content: center;
  top: 0;
  width: 100%;
  height: 150px;
  background-color: #006699;
  display: flex;
  justify-content:right; /* Aligns the items to the right */
  padding: 10px;
 
      }

    .nav-div
    {
           padding-bottom: 120px;
           justify-content: end;
           height: 50px;
           }

    ul {
      list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color:#006699;
    align-items: end;
    
    


  }
    .nav-ul
    {
      align-content: end;
    }

    .img
    {
width: 10px;
height: 10px;
    }

        .logos
    {
      position:fixed;
      z-index: 1000;  /*Ensures the navbar stays on top of other elements */
      top: 0;
      width: 120px;
      height: 120px;
      border-radius: 50%;
       padding: 5px;
          }


          @font-face {
            font-family: CuteFont-Regular;
            src: url("../components/MyFonts/CuteFont-Regular.ttf");
          }
    
         