

.cardbody
{
border-color: white;
border-width: 1px;
background: linear-gradient(white,rgb(87, 214, 228));
padding: 10px;
border-radius: 58% 42% 49% 51% / 0% 100% 0% 100%

}

.div-main:hoverX
{
        animation-name: example;
    animation-duration: 1s;
}

@keyframes exampleX {
    0%   {font-size: 2px;}
    25%  {font-size: 4px;}
    50%  {font-size: 8px;}
    100% {font-size: 12px;}
  }

  @keyframes textAnimationX {
    0% { opacity: 0; transform: translateY(-20px);font-size: 19px; }
    100% { opacity: 1; transform: translateY(0); }
  }


  

  
  .para-details
  {
   opacity: 0;
  transform: translateX(-100%);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
 animation-timeline: view();
  animation: slideIn 1s both;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
   
  }

  @keyframes slideIn {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  