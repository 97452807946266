.div-tab
{
    display: flex;
    justify-content: center;
    align-items: center;
background-color: rgb(242, 233, 233);
Color: white;

}

.div-body
{
    display:block;
    justify-content: center;
    align-items: center;
   
}