.main-container
{
  width: 100%;
  height: 100vh;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: rgb(239, 241, 241);
      }


.container-success
{
  width: 70%;
  height: 25vh;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    background-color: aqua;
      }
