.div-nav
{
    position:sticky;
  z-index: 1000; /* Ensures the navbar stays on top of other elements */
  align-content: center;
  top: 0;
  width: 100%;
  height: 120px;
  background-color: #006699;
  display: flex;
  justify-content:start; /* Aligns the items to the right */
  padding: 10px;
 
    }

.logo2
{
    position:sticky;
    z-index: 1000;  /*Ensures the navbar stays on top of other elements */
    top: 0;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    padding: 5px;
    
}

@keyframes slowRotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


.logo2:hover
{

    animation: slowRotate 0.5s linear ; /* Rotates in 10 seconds continuously */

}


#m-text
{

font-family: "Roboto-Bold";
color: white;
    font-weight: 500;
    font-style:normal;
    font-size: 15px;
   
    }

.navi-link:hover
{
    
    background-color: #006699;
}


@font-face {
    font-family: Roboto-Bold;
    src: url("../components/MyFonts/Roboto-Bold.ttf");
  }


  @media only screen and (max-width: 870px) {
    #m-text {
        color: white;
        background-color: #006699;
        text-shadow: none;
    }
}


@media only screen and (min-width: 870px) {
    
    .navi-link:hover
    {
        
        background-color: #1ca9c9;
    }
    
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

}

.modal-content {
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  max-width: 100%;
  text-align: center;
  }

.modal-content h2 {
  margin-top: 0;
}

.modal-content form {
  margin-bottom: 10px;
  }

.modal-content input {
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content button {
  padding: 8px 12px;
  margin-top: 10px;
  cursor: pointer;
}


.login-button
{
  align-self: flex-end; /* Moves the button to the end horizontally */
  padding: 5px 10px;
  margin-inline-end:auto;
  background-color: #1c33b1;
  color: white;
  border: none;
  cursor: pointer;
font-size: 18px;
font-weight: 500;
  border-radius: 10px;
}

.login-button:hover
{
  background-color: #403aa6;
}