body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .form-container {
    display: flex;
    justify-content: center;
    align-items:start;
    height: 100vh; /* Full height of the viewport */
    background-color: #f0f0f0;
    padding: 20px;
     }
  
  .registration-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
    width: 50%;
  }
  
  .registration-form input {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .registration-form button {
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .registration-form button:hover {
    background-color: #218838;
  }
  