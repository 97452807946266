.video-player {
    margin: 20px auto;
    text-align: center;
  }
  
  h1 {
    color: #333;
    text-align: center;
  }

  