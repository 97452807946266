

.card-container
{
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adds space between the cards */ 
    padding: 20px;
    align-items: center;  
    
    background-image: radial-gradient(grey,whit,yellow);
    justify-content: space-evenly;
   }

.Xcard {
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .cardimg
  {
    width: 100%;
border-radius: 20px;
padding: 5px;
  }

Card
{
height: 10px;

}
  

  