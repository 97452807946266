.div-container
{
width: 100%;
    height:auto;
    display: flex;
    justify-content:space-evenly;
    align-items: flex-start;
    padding: 10px;
       flex-wrap: wrap;
    margin: auto;
      text-align: justify;

}

.nfeed,.levents
{
width:100vh;
height: 100%;
display: flex;
justify-content: space-around;
align-items: center;
flex-wrap: wrap;
margin: auto;
padding: 30px;
border-radius: 18px;
box-shadow: 5px 5px 5px 10px lightgrey;
overflow-x: hidden;

 }


Xnboard
{
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../images/notice.jpg');
    background-size:contain;
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    border-radius: 18px;
    
    
}

.img-class2,.img-class1
{

    width: 100%;
  height: auto;
  max-width: 100%;
  
float: left;
border-radius: 5%;
margin-right: 2px;
padding:3px;
}

.nhead{
    color:orangered;
      
}


.img-eve1,.img-eve2
{
    width: 100%;
    height: auto;
    max-width: 100%;
    
    border-radius: 5%;
    overflow-x: auto;
    overflow-y: auto;
    }


h6{
 font-family: "NotoSerifMalayalam";
        font-weight: 400;
        font-style:normal;
        font-size: 20px;

}

   h7
    {
       
        font-family: "NotoSerifMalayalam";
        font-weight: 200;
        font-style:normal;
        font-size: 18px;
    }

    @font-face {
        font-family: NotoSansMalayalam;
        src: url("./MyFonts/NotoSansMalayalam.ttf");
      }


      @font-face {
        font-family: NotoSerifMalayalam;
        src: url("./MyFonts/NotoSerifMalayalam.ttf");
      } 