.input-container {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  
  .input-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
    border: 1px solid #ddd; /* Border around the label */
    padding: 5px;
    border-radius: 4px; /* Optional: rounded corners for the border */
  }
  
  .text-input {
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;
  }
  