.card-div
{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    gap: 10px; /* Adds space between the cards */ 
    padding: 5px;
    align-items: center;  
    background-color: rgb(250, 246, 246);
    
}

.img-clas
{
   width: 200px;
height: 200px;
border-radius: 50%;
padding: 10px;
margin: auto;
}

Card 
{
padding: 10px;
}

.img-exe
{
    width: 100%;
    height: auto;
    max-width: 100%;
}