.carousel-img {
    height: 400px; /* Set your desired height */
    object-fit: cover; /* Ensures the image covers the entire area */
    width: 100%;
    height: 40rem;
 

    
  }

  h2
  {
   font-style: normal;
   font-weight: 200;
  }

  /* StickyRegister.css */
/* StickyRegister.css */
.sticky-register {
  position: fixed;
 right: 0;
    top: 35%;
    width: 40px;
    height: 50vh;
    display: flex;
    flex-wrap:nowrap;
    justify-content: center;
    align-items: center;
  transform: translateY(-50%);
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 4px 6px rgba(196, 226, 25, 0.1);
  z-index: 9999; /* Ensure it's on top of other components */
}

.sticky-register a {
  background-color:#7FFFD4;
  color: black;
  border-radius: 10px;
  border: 1px;
  border-color: blueviolet;
  text-decoration: none;
  font-weight: bold;
  display:flex;
  transform: rotate(-90deg); /* Rotates the text */
      }

.sticky-register a:hover {
  color: #00ccff;
}

