
.div-container
{
    width: 100%;
    height: auto;
    max-width: 100%;
      display: flex;
    justify-content: right;
    align-items: center;
    background-color: rgb(250, 243, 243);
    margin-left: 300px;
     
   }

   .div-child
   {
    display: flex;
justify-content: center;
align-items: center;
width: 50px;
height: auto;
max-width: 100%;
   }

   .sky-img
   {

border-radius: 10px;
padding: 5px;
   }
   .sky-img:hover
   {
    cursor: zoom-in; 
  }


  .sb-container
  {
        justify-content: center;
    align-items: center;

  }

  @media only screen and (max-width: 870px) {
    
    .sky-img
    {
     width: 33%;
     height: auto;
    }
    
}