.XApp {
    text-align: center;
    margin: 20px;
  }
  
  h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
 