.exe-team-image
{
    height: 100%;
width: auto;

border-radius: 50%;
padding: 10px;

}

.div-container-exe
{
    display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  width: auto;
 }